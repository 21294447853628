import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import HeaderShared from '../../../../shared/Header/header.shared';
import FooterShared from '../../../../shared/Header/footer.shared';
import NotificationsComponent from '../../../../components/Elements/Notifications/notifications.component';
import TallerTableComponent from '../../../../components/Elements/DataTable/tallerTable.component';
import ModalTallerSubmitComponents from '../../../../components/Elements/Modal/ModalTallerSubmit/modaTallerSubmit.component';
import { IWorkshop } from '../../../../types/workshopInterface';
import LoaderComponent from '../../../../components/Loader/loader.component';
import { getWorkshop, updateWorkshop } from '../../../../api/workshopApi';
import { getSession } from '../../../../utils/helpers';
import { AdminPrincipalRol, IAdmin } from '../../../../types/adminInterface';
import { IUser, UserSteps } from '../../../../types/userInterface';
import { searchAdmins } from '../../../../api/adminApi';
import { bulkUpdateUsers } from '../../../../api/usersApi';

const TalleresChecklistScreen: React.FC = () => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = React.useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [workshop, setWorkshop] = useState<Partial<IWorkshop>>({});
  const [adminId, setAdminId] = useState<Partial<IAdmin>>({});
  const [attenders, setAttenders] = useState<IUser[]>([]);
  const [assignedToList, setAssignedToList] = useState<IAdmin[]>([]);
  const [entregadoras, setEntregadoras] = useState<IAdmin[]>([]);

  const { id } = useParams<{ id: string }>();

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const handleButtonClick = () => {
    setModalShow(true);
  };

  const fetchWorkshop = async () => {
    try {
      const session = getSession();
      const response = await getWorkshop(id || '', session?.token || '');
      if (response) {
        setWorkshop(response);
        setAdminId(response.adminId as IAdmin);
        setAttenders([
          ...response.assignedUsers.map((user) => ({
            apuntes: user?.apuntes || user.apuntes || '',
            workshopStatus: user?.status || 'attended',
            ...(user.userId as IUser),
          })),
        ]);
        const adminIds = response.assignedUsers.map(
          (user) => ((user.userId as IUser).assignedTo?.adminId as string) || ''
        );
        const uniqueAdminIds = Array.from(new Set(adminIds));
        const adminsResponse = await searchAdmins(
          1,
          100,
          { ids: uniqueAdminIds },
          session?.token || ''
        );
        if (adminsResponse && adminsResponse.docs) {
          setAssignedToList(adminsResponse.docs);
        }
      }
    } catch (error) {
      setError('Error al cargar el taller');
    } finally {
      setLoading(false);
    }
  };

  const fetchEntregadoras = async () => {
    try {
      const session = getSession();
      const response = await searchAdmins(
        1,
        100,
        {
          principalRol: AdminPrincipalRol.ENTREGADORA,
          colectiva: {
            name: session?.colectiva?.name || '',
          },
        },
        session?.token || ''
      );
      if (response && response.docs) {
        setEntregadoras(response.docs);
      }
    } catch (error) {
      setError('Error al cargar las entregadoras');
    }
  };

  const getDataToUpdate = () => {
    const usersToUpdate = attenders
      .map((user) => {
        return {
          _id: user._id || '',
          step: UserSteps.ENTREGA,
          assignedDeliverer: user.assignedDeliverer || {},
        };
      })
      .filter((user) => user !== undefined) as Partial<IUser>[];

    const workshopUsersData = workshop.assignedUsers?.map((user) => {
      const u = attenders.find((u) => u._id === (user.userId as IUser)?._id);
      return {
        userId: u?._id || '',
        status: u?.workshopStatus || '',
        apuntes: u?.apuntes || '',
      };
    });

    return { usersToUpdate, workshopUsersData };
  };

  const handleConfirm = async () => {
    const { usersToUpdate, workshopUsersData } = getDataToUpdate();
    try {
      setLoading(true);
      setModalShow(false);
      const session = getSession();
      const updateW = await updateWorkshop(
        workshop._id || '',
        { assignedUsers: workshopUsersData || [], status: 'finished' },
        session?.token || ''
      );
      if (updateW) {
        const updateUsers = await bulkUpdateUsers(
          usersToUpdate,
          session?.token || ''
        );
        if (updateUsers) {
          Swal.fire({
            icon: 'success',
            title: 'Taller actualizado',
            text: 'El taller ha sido actualizado correctamente',
          }).then(() => {
            navigate(`/talleres-checklist-terminado/${id}`);
          });
        }
      }
    } catch (error) {
      setError('Error al actualizar el taller');
    } finally {
      setLoading(false);
    }
  };

  const getAsistentes = () => {
    return attenders.filter((user) => user.workshopStatus === 'attended')
      .length;
  };

  const getApuntes = () => {
    return attenders.filter((user) => user.apuntes).length;
  };

  useEffect(() => {
    fetchWorkshop();
    fetchEntregadoras();
  }, []);

  return (
    <>
      <ModalTallerSubmitComponents
        show={modalShow}
        onHide={() => setModalShow(false)}
        handleConfirm={handleConfirm}
        attendersCount={{
          total: workshop.assignedUsers?.length || 0,
          attended: getAsistentes(),
          apuntes: getApuntes(),
        }}
      />
      <HeaderShared />
      <section className="module container">
        {loading && <LoaderComponent />}
        <div className="body-row row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 mb-4">
                <a href="/talleres" className="btn--back">
                  <i className="icon icon--back"></i> Regresar
                </a>
              </div>
              <div className="col-12 mb-5">
                <h1 className="mb-3">
                  Taller {`"${workshop.name || ''}"`} del{' '}
                  {workshop.workshopTextDate}
                </h1>
                <p className="h5 text-regular">
                  Registro de asistencia de mujeres
                </p>
                <p className="text-purple500">
                  <strong>
                    Asistieron {getAsistentes()} de{' '}
                    {(workshop.assignedUsers || []).length} mujeres
                  </strong>
                </p>
                <p>
                  <strong>Impartido por:</strong> {adminId.username || ''}
                </p>
                <p>
                  <strong>Fecha:</strong> {workshop.workshopTextDate}
                </p>
                <p>
                  <strong>Horario:</strong> {workshop.workshopSchedule}
                </p>
                <div className="col-12 mb-3">
                  <NotificationsComponent
                    message={
                      'Recuerda que por cuidado y privacidad de la información, <strong>una vez concluido el taller se eliminará</strong> de tu sesión en 30 días posteriores'
                    }
                    user={{}}
                    status="warning"
                  />
                </div>
                <p className="mb-2">Buscar</p>
                <div className="form-row form--search">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Buscar"
                  />
                  <i className="icon icon--search-bar"></i>
                </div>
              </div>
              <div className="col-12 mb-5">
                <h4 className="mb-4">Mujeres que asistieron al taller</h4>
                <TallerTableComponent
                  attenders={attenders}
                  assignedToList={assignedToList}
                  editAsistentes={true}
                  entregadoras={entregadoras}
                  handleUserDataChange={(user) => {
                    setAttenders(
                      attenders.map((attender) => {
                        if (attender._id === user._id) {
                          return {
                            ...attender,
                            saludGinecologica: user.saludGinecologica,
                            apuntes: user.apuntes,
                            workshopStatus: user.workshopStatus,
                            assignedDeliverer: user.assignedDeliverer,
                          };
                        }
                        return attender;
                      })
                    );
                  }}
                />
              </div>
              <div className="d-flex justify-content-center col-12">
                <button
                  type="button"
                  className="btn btn--type1"
                  onClick={handleButtonClick}
                >
                  Confirmar asistencia y cerrar taller
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default TalleresChecklistScreen;
