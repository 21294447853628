import React from 'react';
import { IUser } from '../../../types/userInterface';

interface InfoDataProps {
  user: Partial<IUser>;
  readonly?: boolean;
  setUser?: React.Dispatch<React.SetStateAction<Partial<IUser>>>;
}

const ViolentometerComponent: React.FC<InfoDataProps> = ({
  user,
  readonly,
}) => {
  return (
    <div className="form-block">
      <form>
        {(user.violentometro?.niveles || []).map((nivel, index) => (
          <div className="form-row" key={index}>
            <label
              htmlFor={`question-${index}`}
              className="form-label label--icon"
            >
              <strong>{`Nivel - ${nivel.id}`}</strong>
            </label>
            <input
              type="text"
              className="form-control"
              id={`question-${index}`}
              name={`question-${index}`}
              readOnly={readonly}
              value={nivel.value || ''}
            />
          </div>
        ))}
      </form>
    </div>
  );
};

export default ViolentometerComponent;
