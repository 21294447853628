import React, { useState, useEffect } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Accordion } from 'react-bootstrap';
import Swal from 'sweetalert2';
import CustomPagination from '../Paginator/paginator.component';
import { searchUsers } from '../../../api/usersApi';
import { calculateWeeksAndDays, getSession } from '../../../utils/helpers';
import { IUser, UserStatus, UserSteps } from '../../../types/userInterface';
import { Link } from 'react-router-dom';
import { differenceInDays, format } from 'date-fns';
import LoaderComponent from '../../Loader/loader.component';
import { IAdmin } from '../../../types/adminInterface';
import { IWorkshop } from '../../../types/workshopInterface';

interface Props {
  searchText: string;
  userSearchTerms?: Partial<
    IUser & { createdAtRange?: { startDate: Date; endDate: Date } }
  >;
}

const AcompananteTableComponent: React.FC<Props> = ({
  searchText,
  userSearchTerms,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1200);
  const [users, setUsers] = useState<IUser[]>([]);
  const [pagination, setPagination] = useState({
    totalRows: 0,
    page: 1,
    limit: 10,
  });

  const setError = (message: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const columns: TableColumn<IUser>[] = [
    {
      name: 'No. Ficha',
      selector: (row) => row.userId,
    },
    {
      name: 'Nombre',
      selector: (row) => row.username,
      format: (row) => {
        return (
          <Link
            to={`/detalle-usuario/${row._id}`}
            className={`text-purple500 text-uppercase ${
              !row.assignedTo || !row.assignedTo.adminId ? 'new' : ''
            }`}
          >
            <strong>{row.username}</strong>
          </Link>
        );
      },
    },
    {
      name: 'Semanas',
      selector: (row) => formatPregnancyCalculator(row),
    },
    {
      name: 'Derivada de',
      selector: (row) => row.derivada?.derivadaA || 'N/A',
    },
    {
      name: 'Acompañante',
      selector: (row) => (row.assignedTo?.adminId as IAdmin)?.username || 'N/A',
    },
    {
      name: 'Folleto',
      selector: (row) =>
        (
          (row.workshopSelected?.workshopId as IWorkshop)?.assignedUsers || []
        ).find((user) => user.userId === row._id)?.apuntes || 'N/A',
      format: (row) => formatFolleto(row),
    },
    {
      name: 'Estado',
      selector: (row: IUser) => row.status,
      format: ({ step, status }) => formatStatus(step, status),
    },
    {
      name: 'Hora de registro',
      selector: (row) => row.createdAt,
      format: (row) => {
        const time = format(new Date(row.createdAt), 'hh:mm a');
        const dateFormatted = format(new Date(row.createdAt), 'dd/MM/yy');
        return (
          <div className="data-table__time">
            <span style={{ marginRight: 8 }}>{time}</span>
            <span>{dateFormatted}</span>
          </div>
        );
      },
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row: IUser) =>
        differenceInDays(new Date(), new Date(row.createdAt)) < 12,
      style: {
        backgroundColor: '#FCEEF2',
      },
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const formatStatus = (step: number, status: UserStatus) => {
    if (step <= UserSteps.INCIDENCIAS) {
      return (
        <span className="badge badge--status badge--status-success">
          Registrada
        </span>
      );
    } else if (
      status === UserStatus.BLOCKED ||
      status === UserStatus.INACTIVE
    ) {
      return (
        <span className="badge badge--status badge--status-info">
          No logrado
        </span>
      );
    } else if (step === UserSteps.MI_PROCESO) {
      return (
        <span className="badge badge--status badge--status-success">
          En espera
        </span>
      );
    } else if (step === UserSteps.ACOMPANAMIENTO) {
      return (
        <span className="badge badge--status badge--status-done">Asignada</span>
      );
    } else if (step === UserSteps.TALLER) {
      return (
        <span className="badge badge--status badge--status-taller">
          En taller
        </span>
      );
    } else if (step === UserSteps.ENTREGA) {
      return (
        <span className="badge badge--status badge--status-done">
          En entrega
        </span>
      );
    } else if (step > UserSteps.ENTREGA) {
      return (
        <span className="badge badge--status badge--status-done">Logrado</span>
      );
    } else if (step === UserSteps.SEGUIMIENTO) {
      return (
        <span className="badge badge--status badge--status-info">
          Seguimiento
        </span>
      );
    } else {
      return (
        <span className="badge badge--status badge--status-info">{status}</span>
      );
    }
  };

  const formatFolleto = (row: IUser) => {
    const apuntes =
      (
        (row.workshopSelected?.workshopId as IWorkshop)?.assignedUsers || []
      ).find((user) => user.userId === row._id)?.apuntes || 'N/A';
    return <span className="data-table__number">{apuntes || 'N/A'}</span>;
  };

  const formatPregnancyCalculator = (row: IUser) => {
    const {
      saludGinecologica: { pregnancyCalculator },
    } = row;
    if (pregnancyCalculator) {
      const { result, dateResult } = pregnancyCalculator;
      if (dateResult) {
        const { semanas, dias } = calculateWeeksAndDays(new Date(dateResult));
        return `${semanas} semanas ${dias} días`;
      }
      return result || 'N/A';
    }
    return 'N/A';
  };

  const updateMedia = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  const fetchUsers = async () => {
    try {
      if (searchText === '') {
        setLoading(true);
      }
      const session = getSession();
      const response = await searchUsers(
        session?.token || '',
        { searchText, ...userSearchTerms },
        {
          page: pagination.page,
          limit: pagination.limit,
        }
      );
      setUsers(response.docs || []);
      setPagination({
        ...pagination,
        totalRows: response.totalDocs || 0,
      });
      console.log('response', response);
    } catch (error) {
      setError('Error al cargar los datos');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [searchText, userSearchTerms, pagination.page, pagination.limit]);

  return (
    <div className="data-table">
      {loading && <LoaderComponent />}
      {!isMobileView ? (
        <DataTable
          columns={columns}
          data={users}
          conditionalRowStyles={conditionalRowStyles}
          pagination
          paginationComponentOptions={paginationComponentOptions}
          paginationComponent={(props) => {
            const handleChangePage = (page: number) => {
              setPagination({ ...pagination, page });
              console.log('Changing page to', page);
              // Asume que props.onChangePage realmente espera un solo argumento.
              // Esto es para diagnóstico; revisa la consola para ver qué se registra cuando cambias de página.
              (props.onChangePage as (page: number) => void)(page);
            };

            return (
              <div className="data-paginator">
                <CustomPagination
                  rowsPerPage={props.rowsPerPage}
                  rowCount={props.rowCount}
                  onChangePage={handleChangePage}
                  currentPage={currentPage}
                />
              </div>
            );
          }}
          onChangePage={(page) => setCurrentPage(page)}
          paginationPerPage={pagination.limit}
          paginationTotalRows={pagination.totalRows}
        />
      ) : (
        <section className="data-table-mobile">
          <div className="data-table-mobile__header">
            <p>
              <strong>Nombre</strong>
            </p>
          </div>
          <Accordion className="data-table-mobile__accordion">
            {users.map((row, index) =>
              (() => {
                const time = format(new Date(row.createdAt), 'hh:mm a');
                const dateFormatted = format(
                  new Date(row.createdAt),
                  'dd/MM/yy'
                );
                return (
                  <Accordion.Item eventKey={String(index)} key={index}>
                    <Accordion.Header>{row.username}</Accordion.Header>
                    <Accordion.Body>
                      <table>
                        <tr>
                          <td>
                            <strong>No. Ficha:</strong>
                          </td>
                          <td className="text-center">{row.userId}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Semanas:</strong>
                          </td>
                          <td className="text-center">
                            {formatPregnancyCalculator(row)}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Derivada de:</strong>
                          </td>
                          <td className="text-center">
                            {row.derivada.derivadaA || 'N/A'}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Folleto:</strong>
                          </td>
                          <td className="text-center">
                            <span className="data-table__number">
                              {row.apuntes || 'N/A'}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Estado:</strong>
                          </td>
                          <td className="text-center">
                            {formatStatus(row.step, row.status)}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Hora de registro:</strong>
                          </td>
                          <td className="text-center">
                            <div className="data-table__time">
                              <span style={{ marginRight: 8 }}>{time}</span>
                              <span>{dateFormatted}</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="text-center">
                            <a
                              href="/detalle-usuario"
                              className="btn btn--type2"
                            >
                              <strong>Ver ficha</strong>
                            </a>
                          </td>
                        </tr>
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })()
            )}
          </Accordion>
        </section>
      )}
    </div>
  );
};

export default AcompananteTableComponent;
