import { IAdmin } from './adminInterface';
import { IUser } from './userInterface';

export enum WorkshopStatus {
  PENDING = 'pending',
  ACTIVE = 'active',
  RESCHEDULED = 'rescheduled',
  CANCELLED = 'cancelled',
  FINISHED = 'finished',
}

export enum IWorkshopAssignedUserStatus {
  ATTENDED = 'attended',
  NOT_ATTENDED = 'notAttended',
}

export interface IWorkshopAssignedUser {
  userId: string | IUser;
  status: string;
  apuntes?: string;
  createdAt?: Date;
}

export interface IWorkshop {
  _id: string;
  workshopId: number;
  adminId: string | IAdmin;
  assignedUsers: IWorkshopAssignedUser[];
  name: string;
  availablePlaces: number;
  workshopDate: Date | string;
  workshopTextDate: string;
  workshopSchedule: string;
  workshopType: string;
  workshopDescription: string;
  colectiva: string;
  status: string;
  chatRoomId: string;
  isFull: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface IWorkshopPaginate {
  docs: IWorkshop[];
  totalDocs?: number;
  offset: number;
  limit: number;
  totalPages?: number;
  page?: number;
  pagingCounter?: number;
  hasPrevPage?: boolean;
  hasNextPage?: boolean;
  prevPage?: number;
  nextPage?: number;
}
