import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import HeaderShared from '../../../shared/Header/header.shared';
import FooterShared from '../../../shared/Header/footer.shared';
import AdminCardProps from '../../../components/Elements/AdminCard/adminCard.component';
import ReactSearchAutocomplete from 'react-search-autocomplete/dist/components/ReactSearchAutocomplete';
import CustomPagination from '../../../components/Elements/Paginator/paginator.component';
import { AdminPrincipalRol, IAdmin } from '../../../types/adminInterface';
import { getSession } from '../../../utils/helpers';
import { searchAdmins } from '../../../api/adminApi';
import LoaderComponent from '../../../components/Loader/loader.component';

interface Props {
  principalRol: AdminPrincipalRol;
}

const CoordinadorasScreen: React.FC<Props> = ({ principalRol }) => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [admins, setAdmins] = useState<IAdmin[]>([]);
  const [pagination, setPagination] = useState({
    totalDocs: 0,
    page: 1,
    limit: 16,
    totalPages: 0,
  });

  const navigate = useNavigate();

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const fetchAdmins = async () => {
    try {
      if (searchText === '') {
        setLoading(true);
      }

      const session = getSession();
      if (!session || !session.token) {
        navigate('/login');
        return;
      }

      const coordinadoras = await searchAdmins(
        pagination.page,
        pagination.limit,
        { principalRol, searchText },
        session.token
      );

      setAdmins(coordinadoras.docs);
      setPagination({
        totalDocs: coordinadoras.totalDocs || 0,
        limit: coordinadoras.limit || 50,
        page: coordinadoras.page || 1,
        totalPages: coordinadoras.totalPages || 0,
      });
      setLoading(false);
    } catch (error) {
      setError('Error al obtener los administradores');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, [searchText, pagination.page, pagination.limit]);

  return (
    <>
      <HeaderShared />
      <section className="module container">
        {loading && <LoaderComponent />}
        <div className="body-row row mb-4">
          <div className="col-12">
            <div className="auth-row row">
              <div className="col-12 mb-4">
                <h1>Equipo de Las Amigas</h1>
                <p className="text-20">
                  <strong>{principalRol}</strong>
                </p>
              </div>
              <div className="col-12 mb-4">
                <Link to="/equipo-de-las-amigas" className="btn--back">
                  <i className="icon icon--back"></i> Regresar
                </Link>
              </div>
              <div className="col-12">
                <h2 className="h3">Busca entre Las Amigas</h2>
                <div className="form-search-complete">
                  <div className="autocomplete-search search--100">
                    <ReactSearchAutocomplete
                      items={[]}
                      autoFocus
                      onSearch={(keyword) => setSearchText(keyword)}
                      placeholder="Buscar colectiva"
                      className="autocomplete-search__input"
                      showItemsOnFocus={false}
                      showNoResults={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mt-4 user-info-tab">
            <h4 className="user-info-tab__subtitle-right text-20 text-regular text-purple800">
              {admins.length} {principalRol}s registradas
            </h4>
            <hr className="mt-0 hr-pink" />
            <div className="row">
              {admins.map((admin, index) => (
                <div
                  className="user-info-tab__col col-md-3 col-sm-6 col-12"
                  key={index}
                >
                  <AdminCardProps
                    name={admin.username}
                    role={admin.principalRol}
                    subRole={admin.secondaryRol}
                    url={`/detalle-coordinadora/${admin._id}`}
                    title="h3"
                    colectiva={admin.colectiva?.name || ''}
                  />
                </div>
              ))}
              <div className="data-paginator">
                <CustomPagination
                  rowCount={pagination.totalDocs}
                  rowsPerPage={pagination.limit}
                  currentPage={pagination.page}
                  pagesToShow={pagination.totalPages}
                  onChangePage={(page: number) => {
                    setPagination({
                      ...pagination,
                      page,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default CoordinadorasScreen;
