import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { io, Socket } from 'socket.io-client';
import HeaderShared from '../../../shared/Header/header.shared';
import FooterShared from '../../../shared/Header/footer.shared';
import TalleresComponent from '../../../components/Elements/Talleres/talleres.component';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { IWorkshop } from '../../../types/workshopInterface';
import { getSession } from '../../../utils/helpers';
import { searchWorkshops } from '../../../api/workshopApi';
import LoaderComponent from '../../../components/Loader/loader.component';
import { AdminPrincipalRol, IAdmin } from '../../../types/adminInterface';
import { useParams } from 'react-router-dom';

interface Item {
  id: number;
  name: string;
}

const TalleresScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState<IAdmin>();
  const [socket, setSocket] = useState<Socket>();
  const [workshops, setWorkshops] = useState<IWorkshop[]>([]);
  const [workshopType, setWorkshopType] = useState<string>('');
  const [searchProps, setSearchProps] = useState<{
    page: number;
    limit: number;
    searchText: string;
    adminId: string;
  }>({
    page: 1,
    limit: 50,
    searchText: '',
    adminId: '',
  });

  const { id: adminId } = useParams<{ id: string }>();

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const formatResult = (item: Item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    );
  };

  const fetchWorkshops = async () => {
    try {
      if (searchProps.searchText === '') {
        setLoading(true);
      }
      const session = getSession();
      if (session) {
        const response = await searchWorkshops(
          session?.token || '',
          {
            page: searchProps.page,
            limit: searchProps.limit,
          },
          {
            adminId: adminId
              ? adminId
              : [session?.principalRol, session?.secondaryRol].includes(
                  AdminPrincipalRol.TALLERISTA
                )
              ? session?._id || ''
              : '',
            searchText: searchProps.searchText,
            workshopType,
          }
        );
        setWorkshops(response.docs);
        setAdmin(session);
      }
    } catch (error) {
      setError('Ocurrió un error al obtener los talleres');
    } finally {
      setLoading(false);
    }
  };

  const getSocket = async () => {
    const socket = io('https://chat.conlasamigas.org/');
    socket.on('connect', () => {
      console.log('Connected to server');
    });
    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    });
    setSocket(socket);
  };

  useEffect(() => {
    fetchWorkshops();
  }, [searchProps, workshopType]);

  useEffect(() => {
    getSocket();
  }, []);

  return (
    <>
      <HeaderShared />
      <section className="module container">
        {loading && <LoaderComponent />}
        <div className="body-row row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 mb-5">
                <h1 className="mb-4">Tus talleres</h1>
                <div className="row">
                  <div className="col-6 mb-3">
                    <p>
                      Puedes buscar por: <strong>Nombre, Hora, Fecha</strong>
                    </p>
                    <div className="form-search-complete">
                      <div className="autocomplete-search">
                        <ReactSearchAutocomplete
                          items={[]}
                          autoFocus
                          onSearch={(keyword) => {
                            setSearchProps({
                              ...searchProps,
                              searchText: keyword,
                            });
                          }}
                          formatResult={formatResult}
                          placeholder="Buscar talleres"
                          className="autocomplete-search__input"
                          showItemsOnFocus={false}
                          showNoResults={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-3 mb-3">
                    <p>Puedes buscar por tipo:</p>
                    <select
                      className="form-select"
                      aria-label="Tipos de talleres"
                      defaultValue={'default'}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value === 'default') {
                          setWorkshopType('');
                        } else {
                          setWorkshopType(value);
                        }
                      }}
                    >
                      <option value="default">Todos</option>
                      <option value="En línea">En línea</option>
                      <option value="Teléfono">Teléfono</option>
                      <option value="Presencial">Presencial</option>
                    </select>
                  </div>
                  <div className="col-3 mb-3" style={{ textAlign: 'center' }}>
                    <p>&nbsp;</p>
                    {[admin?.principalRol, admin?.secondaryRol].includes(
                      AdminPrincipalRol.TALLERISTA
                    ) && (
                      <a href="/crear-taller" className="btn btn--type1">
                        + Nuevo taller
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <div className="talleres-col col-12 mb-4">
                <h2 className="h4 mb-3">Talleres próximos a impartir</h2>
                <div className={`row justify-content-start`}>
                  {workshops
                    .filter(({ status }) => status === 'active')
                    .map((workshop, index) => (
                      <TalleresComponent
                        key={index}
                        mode="ver-mas"
                        showAvailableSlotsBadge={true}
                        showStockList={true}
                        cols="col-xl-3"
                        workshop={workshop}
                        tallerEstado="proximos"
                        setLoading={setLoading}
                        socket={socket}
                        showChat={adminId ? false : true}
                        fetchWorkshops={fetchWorkshops}
                      />
                    ))}
                </div>
              </div>
              <div className="talleres-col col-12  mb-4">
                <h2 className="h4 mb-3">Talleres reagendados</h2>
                <div className={`row justify-content-start`}>
                  {workshops
                    .filter(({ status }) => status === 'rescheduled')
                    .map((workshop, index) => (
                      <TalleresComponent
                        key={index}
                        mode="ver-mas"
                        showAvailableSlotsBadge={true}
                        showStockList={true}
                        cols="col-xl-3"
                        workshop={workshop}
                        tallerEstado="reagendados"
                        setLoading={setLoading}
                        socket={socket}
                        showChat={adminId ? false : true}
                        fetchWorkshops={fetchWorkshops}
                      />
                    ))}
                </div>
              </div>
              <div className="talleres-col col-12">
                <h2 className="h4 mb-3">Talleres concluídos</h2>
                <div className={`row justify-content-start`}>
                  {workshops
                    .filter(({ status }) => status === 'finished')
                    .map((workshop, index) => (
                      <TalleresComponent
                        key={index}
                        mode="ver-mas"
                        showAvailableSlotsBadge={false}
                        showStockList={false}
                        cols="col-xl-3"
                        workshop={workshop}
                        tallerEstado="concluidos"
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default TalleresScreen;
